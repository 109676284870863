import React, { useEffect, useState } from 'react'


import { palette, sections } from '../constants';


import { SectionModule } from './SectionModule'

import '../css/section-showreel.css'


export const SectionsModule = ({ sectionClicked }) => {
    const sectionKeys = Object.keys(sections);
    const sectionModules = sectionKeys.map((sectionKey, index) => {
      const name = sections[sectionKey].name;
      const id   = sections[sectionKey].id;
      const left = sections[sectionKey].left;
      const top  = sections[sectionKey].top;
  
      return React.createElement(SectionModule, {
        id: id,  
        palette: palette,  
        name: name,
        left: left,
        top: top,
        sectionClicked: sectionClicked,
      });
    });
  


    return (
      React.createElement(
        'div',
        {
          className: 'sections-module',
        },
        sectionModules // Render all instantiated SectionModules
      )
    )
  };
  
