import React, { useEffect, useState } from 'react'


import { palette, sections } from '../constants';



export const PriceHeaderModule = ({ nameValue }) => {

    return (
      React.createElement('div', {
        className: 'price-header-module',
        style: {
          display: 'flex',
          justifyContent: 'space-between',
          width: '40vw',
          fontSize: '60px',
          color: palette[4]
  
        }
      },
      [nameValue])
    )
  }
  
  