import React, { useEffect, useState } from 'react'


import { palette, sections } from '../constants';




export const PriceItemModule = ({ itemName, priceValue }) => {

    return (
      React.createElement('div', {
        className: 'price-item-module',
        style: {
          display: 'flex',
          justifyContent: 'space-between',
          width: '40vw',
          fontSize: '36px',
  
        }
      },
      [
        React.createElement('div', {
          style: {
            color: 'white',
          }
        }, itemName),
        React.createElement('div', {
          style: {
            color: palette[0],
            fontWeight: '700',
          }
        }, priceValue),
      ])
    )
  }
  
  
  