import React, { useEffect, useState } from 'react'
//import ReactDOM from 'react-dom'

import './bin/css/App.css'


//import sitelogo from './bin/images/logo_256x256.png'

//import igLogo from './bin/images/ig-logo.png'
//import fbLogo from './bin/images/fb-logo.png'
//import ytLogo from './bin/images/yt-logo.png'


import {AppHeaderBlock} from './bin/blocks/AppHeaderBlock'
import {FirstBlock}     from './bin/blocks/FirstBlock'
import {SectionModule}  from './bin/blocks/SectionModule'
import {SectionsModule} from './bin/blocks/SectionsModule'
import {StartModule}    from './bin/blocks/StartModule'
import {PriceHeaderModule} from './bin/blocks/PriceHeaderModule'
import {PriceItemModule}   from './bin/blocks/PriceItemModule'
import {TarifModule}       from './bin/blocks/TarifModule'
import {EmptyModule}       from './bin/blocks/EmptyModule'

import {MusicModule}     from './bin/blocks/MusicModule'
import {PostProdModule } from './bin/blocks/PostProdModule'


import { palette, sections, tarif1Items, tarif2Items, tarif3Items, tarif4Items } from './bin/constants'


//on injecte la palette de couleurs au CSS
document.documentElement.style.setProperty('--pcolor_0', palette[0]); 
document.documentElement.style.setProperty('--pcolor_1', palette[1]);
document.documentElement.style.setProperty('--pcolor_2', palette[2]); 
document.documentElement.style.setProperty('--pcolor_3', palette[3]); 
document.documentElement.style.setProperty('--pcolor_4', palette[4]); 

//export var actualBackgroundColor = palette[2];

export var storedActualBgColor = palette[2];





// Function to set the CSS variable for the background color
function setBodyBackgroundColor(color, fadeStartColor, fadeEndColor) {
  document.documentElement.style.setProperty('--actual-bg-color', color);
//  document.documentElement.style.setProperty('--fade-start-color', fadeStartColor); 
//  document.documentElement.style.setProperty('--fade-end-color',   fadeEndColor); 
  console.log('setBodyBackgroundColor called');
}






function App() {

  const [loaded, setLoaded] = useState(false);
  const [clickedSection, setClickedSection] = useState("");
  const [validSection, setValidSection] = useState(false);
  const [validFadeSection, setValidFadeSection] = useState(false);


  const [fadeStartColor, setFadeStartColor] = useState(null);
  const [fadeEndColor, setFadeEndColor] = useState(null);






  // Set initial actualBackgroundColor to palette[2]
  const [actualBackgroundColor, setActualBackgroundColor] = useState(storedActualBgColor);



  useEffect(() => {const timer  = setTimeout(() => {setLoaded(true)},         0); return () => clearTimeout(timer)},  []);





  const sectionClicked = (key) => {
    const component = componentsDictionary[key];
    setInputComponent(component);
    setClickedSection(key);
  


    // Get the current background color
    const currentBgColor = actualBackgroundColor; // transition start color
  



    // Set the body background color to the specified section color
    //setBodyBackgroundColor(sections[key].color, currentBgColor, sections[key].color); // Set fadeStartColor to the current background color
  



    // Trigger animation by setting fadeStartColor and fadeEndColor
    setFadeStartColor(currentBgColor); // Update fadeStartColor with the current background color
    setFadeEndColor(sections[key].color);




    // Update actualBackgroundColor to the new color
    setActualBackgroundColor(sections[key].color);   //  transition start color 
  



    // screen scrolldown SECTION OPENED
    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth'
      });
        setValidSection(true);
    //  setValidFadeSection(true);
      //storedActualBgColor = 'red';//sections[key].color;
      
    }, 50); // MICRO DELAY POUR SCROLLDOWN VALIDE
  



    
  // Reset validSection after animation is completed
  setTimeout(() => {
    
    //setActualBackgroundColor(sections[key].color); // Update the actual background color
    setValidSection(false);
    
  }, 10); // Wait for 2 seconds after animation is completed




  };






  const [inputComponent, setInputComponent] = useState(EmptyModule);




  const componentsDictionary = {
    musique:  MusicModule({ loaded: loaded, palette: palette }),
    postProd: PostProdModule({ loaded: loaded, palette: palette })

    // Add more mappings as needed
  };





  

  return React.createElement(
    'div',
    {
      className: 'App',
      style: {
        '--fade-start-color': fadeStartColor,
        '--fade-end-color'  : fadeEndColor,
        backgroundColor: validSection ? fadeStartColor : 'none',
        animation: [
          validSection ?  'fadeBgCustom 2s forwards' : 'none',
        ],

        }
    },
    [
      //headblock
      React.createElement('div',{className: 'headblock'},[React.createElement('div', {}, React.createElement(AppHeaderBlock))]),
      
      //first block
      React.createElement('div', null, React.createElement(FirstBlock,{loaded: loaded, sectionClicked: sectionClicked, palette: palette})),

      // inputComponent
      React.createElement("div", null, inputComponent),
 

  //    React.createElement( 'div', { className: 'tarifs-holder' }, [
  //      React.createElement('div', null, React.createElement(TarifModule,  { palette: palette, nameValue: "Musique",         tariffItems: tarif1Items })),
  //      React.createElement('div', null, React.createElement(TarifModule,  { palette: palette, nameValue: "Post-Production", tariffItems: tarif2Items })),
  //      React.createElement('div', null, React.createElement(TarifModule,  { palette: palette, nameValue: "Composition",     tariffItems: tarif3Items })),
  //      React.createElement('div', {},   React.createElement(TarifModule,  { palette: palette, nameValue: "Autres services", tariffItems: tarif4Items })),
  //    ]),





    ]
  );
}









// Create a root and render the App component  ??? INUTILE ???
//const root = ReactDOM.createRoot(document.getElementById('root'));
//root.render(<App />);


export default App;