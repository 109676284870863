import React, { useEffect, useState } from 'react'


import { palette, sections } from '../constants';

import testBG from '../images/img-cf.jpg'

import { StartModule } from './StartModule'


//debug
import { MusicModule } from './MusicModule';
import {PostProdModule } from './PostProdModule';



export const FirstBlock = ({ loaded, sectionClicked, palette }) => {

    return React.createElement(
      'div',
      {
        className: '',
        style: {
          position: 'relative',
          width: '100%',
          
          marginTop: '3vh',
          
          height: '90vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
         // backgroundColor: palette[0],

         overflow: 'hidden',
        }
      },
      [
        React.createElement('img', 
          {
          src: testBG,
          alt: 'Icon',
          className: 'first-block-bg' + (loaded ? ' loaded' : ''),
          style: {
            position: 'absolute',
            backgroundColor: 'rgb(26, 26, 26)',
            opacity: '0',
            width: '100%',
            transition: 'opacity 2s',
            zIndex: '-1',
            backgroundSize: '100% auto',
            backgroundPosition: 'center',


            //display: 'none',
          }
        }
        
      ),
      //-d?--------------------------------------------------------------------MODULE DEBUG A LA PLACE DE STARTMODULE
        React.createElement('div',{ className: 'first-block' }, [ React.createElement(
            StartModule, { sectionClicked: sectionClicked }
        //    MusicModule, { loaded:loaded, palette: palette }
        ) ])
        ]
    );
  };
 
  