

// Tarif 1: Studio d'enregistrement musical
export const tarif1Items = [
    { itemName: "Enregistrement d'une chanson", priceValue: "---€" },
    { itemName: "Enregistrement d'un EP (<5 chansons)", priceValue: "---€" },
    { itemName: "Enregistrement d'un album (<10 chansons)", priceValue: "----€" },
    { itemName: "Mixage et mastering d'une chanson", priceValue: "---€" },
    { itemName: "Séance de coaching vocal", priceValue: "---€" },
    { itemName: "Location de matériel audio", priceValue: "---€" }
  ];
  
  // Tarif 2: Studio de post-production audiovisuelle
  export const tarif2Items = [
    { itemName: "Montage Son", priceValue: "---€" },
    { itemName: "Composition de musique originale pour film", priceValue: "---€" },
    { itemName: "Mixage audio 5.1", priceValue: "---€/Jour" },
    { itemName: "Effets spéciaux (par séquence)", priceValue: "---€" },
  ];
  
  // Tarif 3: Services de composition musicale
  export const tarif3Items = [
    { itemName: "Composition de musique pour film (par minute)", priceValue: "100€" },
    { itemName: "Création de bande sonore pour jeu vidéo", priceValue: "800€" },
    { itemName: "Production de beatmaking sur mesure", priceValue: "150€" },
    { itemName: "Arrangement musical pour chanson", priceValue: "200€" },
    { itemName: "Création de jingle publicitaire", priceValue: "300€" },
    { itemName: "Composition de musique orchestrale", priceValue: "1000€" },
    { itemName: "Mixage et mastering de composition", priceValue: "120€" },
    { itemName: "Consultation en composition musicale", priceValue: "70€" }
  ];
  
  // Tarif 4: Tarifs spéciaux "Surprise"
  export const tarif4Items = [
    { itemName: "Expérience musicale immersive (1 heure)", priceValue: "250€" },
    { itemName: "Session d'enregistrement en plein air", priceValue: "500€" },
    { itemName: "Création d'un album conceptuel interactif", priceValue: "1500€" },
    { itemName: "Séance de création de musique improvisée", priceValue: "100€" },
    { itemName: "Concert privé dans un lieu insolite", priceValue: "1000€" },
    { itemName: "Expérience de réalité virtuelle musicale", priceValue: "300€" },
    { itemName: "Atelier de création sonore pour enfants", priceValue: "50€" },
    { itemName: "Service de musique personnalisée pour événements", priceValue: "200€" }
  ];
  
  
  




  
  export const palette = [
    '#A52502',
    '#390D02',
    '#000B0D',
    '#154B52',
    '#1C8394'
    ];
  
  





    


export const sections = {
  "musique":{
    name:"Musique",
    id: "musique",
    left: "30vw",
    top: "14vh",
    color: palette[4],
  },
  "postProd":{
    name:"Post-Production",
    id: "postProd",
    left: "10vw",
    top: "60vh",
    color: palette[3],
  },
  "soundDesing":{
    name:"Sound Design",
    id: "soundDesing",
    left: "70vw",
    top: "34vh",
    color: palette[2],
  },
  "projets":{
    name:"Projets",
    id: "projets",
    left: "40vw",
    top: "40vh",
    color: palette[1],
  },
  "contact":{
    name:"Contact",
    id: "contact",
    left: "55vw",
    top: "60vh",
    color: palette[0],
  },
}
