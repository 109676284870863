import React, { useEffect, useState } from 'react'


import { sections, tarif1Items, tarif2Items, tarif3Items, tarif4Items } from '../constants'

//import testBG from '../images/img-cf.jpg'
import cab1BG from '../images/lbs-cabine-1.jpeg'

import { StartModule } from './StartModule'

import { TarifModule } from './TarifModule'






export const MusicModule = ({ loaded, palette }) => {

    return React.createElement(
      'div',
      {
        className: '',
        style: {
          position: 'relative',
          width: '100%',
          //marginTop: '3vh',
          height: '90vh', // 90 - marginTop
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
          //backgroundColor: palette[1],  //ne pas appliquer car c'est mieux de changer le bg du site entier
        }
      },
      [
              

        //image
        React.createElement('img', {
            key: 'image',
            src: cab1BG,
            alt: 'Icon',
            style: {
              position: 'absolute',
              top: '0px',
              width: '100%',
              zIndex: '0',
              maskImage: 'linear-gradient(to top, rgba(0,0,0,0.3), rgba(0,0,0,0))',
              WebkitMaskImage: 'linear-gradient(to top, rgba(0,0,0,0.3), rgba(0,0,0,0))', // For Safari
            }
          }),



                  //content
        React.createElement(
          'div',
          {
            style: {
          //    backgroundColor: palette[1],
          //    width: '200px',
          //    height: '200px',
              color: 'white',
              opacity: '1',
              zIndex: '1',
            }
          },
          [React.createElement(TarifModule, { palette: palette, nameValue: "Musique", tariffItems: tarif1Items })]
        ),
        
        
      ]
    );
  };
 
  