import React, { useEffect, useState } from 'react'

import sitelogo from '../images/logo_256x256.png'

import igLogo from '../images/ig-logo.png'
import fbLogo from '../images/fb-logo.png'
import ytLogo from '../images/yt-logo.png'

import { palette, sections } from '../constants';




export const AppHeaderBlock = () => {

    return (
  
      React.createElement('div', {
        className: 'appheader-block',
        style: {
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#000B0D',
          boxShadow: '0 1vh 2vh 1vh #000B0D',
          width: '100%',
          height: '7vh',
      
      
        }
      }, [
      React.createElement(
        'button',
        {
          className: 'appheader-siteicon-btn',
          style: {
              display: 'flex',
              alignItems: 'center',
              margin: '0px 10px',
              width: '4vw',
              height: '4vw',
              cursor: 'pointer',
              backgroundColor: 'transparent',
              border: 0,
              opacity: 1,
              display: 'none',
          },
        },
        [
          React.createElement('img', { src: sitelogo, alt: 'Icon', className: 'appheader-siteicon-img'})
        ]
      ),
  
      React.createElement(
        'div',
        {
          className: 'sitename-holder',
          style: {
            marginLeft: '20px',
            color: '#DCEEF2',
            fontSize: '30px',
          }
          
          },
        [
        React.createElement(
          'div',
          {
            className: 'sitename-part1',
            style: {
              marginBottom: '-18px',
              cursor: 'pointer',
            }
          },
          "La Baraque"
        ),
        React.createElement(
          'div',
          {
            className: 'sitename-part2',
            style:{
              fontSize: '48px',
              cursor: 'pointer',
              color: palette[4],
              
            },
          },
          "Studio"
        ),
      ]),
      React.createElement(
        'div',
        {
          style: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            position: 'fixed',
            width: '140px',
            color: 'rgb(150, 150, 150)',
            fontSize: '14px',
            right: '12px',
          }
        },
        [
        React.createElement('img', {
          src: igLogo,
          alt: 'Icon',
          style: {
            width: '40px',
            height: '40px',
            cursor: 'pointer',
          }
        }),
  
        React.createElement('img', {
          src: fbLogo,
          alt: 'Icon',
          style: {
            width: '40px',
            height: '40px',
            cursor: 'pointer',
          }
        }),
        
        React.createElement('img', {
          src: ytLogo,
          alt: 'Icon',
          style: {
            width: '40px',
            height: '40px',
            cursor: 'pointer',
          }
        }) 
  
      ])
    ])
  
  
    )
  };
  
