import React, { useEffect, useState } from 'react'


import { palette, sections } from '../constants';

import {PriceHeaderModule} from './PriceHeaderModule'
import {PriceItemModule} from './PriceItemModule'




// Modify TarifModule to accept tariff items array as props
export const TarifModule = ({ palette, nameValue, tariffItems }) => {
    return (
      React.createElement('div', {
        style: {
          backgroundColor: palette[1],
          padding: '20px',
          borderRadius: '16px',
          margin: '10px',
        }
      },
      [
        React.createElement('div', null, React.createElement(PriceHeaderModule, { nameValue: nameValue, palette: palette })),
        // Dynamically create PriceItemModule for each item in tariffItems array
        tariffItems.map((item, index) => (
          React.createElement('div', { key: index }, React.createElement(PriceItemModule, { itemName: item.itemName, priceValue: item.priceValue }))
        ))
      ])
    );
  }
  
  