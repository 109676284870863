
import React, { useEffect, useState } from 'react'


import { palette, sections } from '../constants';


import { SectionsModule } from './SectionsModule'



export const StartModule = ({ sectionClicked }) => {
    // State to track whether the component has finished loading
    const [loadedBx, setLoadedBx]     = useState(false);
    const [loaded, setLoaded]         = useState(false);
    const [loaded2, setLoaded2]       = useState(false);
    const [loaded3, setLoaded3]       = useState(false);
    const [loadedNext, setLoadedNext] = useState(false);
    const [loadedSlide, setLoadedSlide] = useState(false);
  
  
  
    useEffect(() => {
      const timer = setTimeout(() => {setLoadedBx(true)}, 0);
      return () => {
        clearTimeout(timer);
        console.log(`Timer BX End!`);
      }
    }, []);
  
  
  
    useEffect(() => {
      const timer2 = setTimeout(() => {setLoaded(true)}, 0);
      return () => {
        clearTimeout(timer2);
        console.log(`Timer 2 Start!`);
      }
    }, []);
  
  
  
    useEffect(() => {
      const timer3 = setTimeout(() => {setLoaded2(true)}, 2000);
      return () => {
        clearTimeout(timer3);
        console.log(`Timer 3 Start!`);
      }
    }, []);
  
  
  
    useEffect(() => {
      const timer4 = setTimeout(() => {setLoaded3(true)}, 2400);
      return () => {
        clearTimeout(timer4);
        console.log(`Timer 4 Start!`);
      }
    }, []);
  
  
  
    useEffect(() => {
      const timer5 = setTimeout(() => {setLoadedNext(true)}, 2800);
      return () => {
        clearTimeout(timer5);
        console.log(`Timer 5 Start!`);
      }
    }, []);
  
  
  
    useEffect(() => {
      const timer6 = setTimeout(() => {setLoadedSlide(true)}, 3000);
      return () => {
        clearTimeout(timer6);
        console.log(`Timer 6 Start!`);
      }
    }, []);
  
  
  
  
  
    return (
      React.createElement(
        'div',
        {
          className: loaded ? 'start-module loaded' : 'start-module', // Add loaded class conditionally
          style: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            overflow: 'hidden',
  
          //  backgroundColor: 'green',
            
          }
        },
        [
          React.createElement(
            'div',
            {
              className: "animtext-labaraque",
              //key: 'text',
              style: {
                color: 'white',
                fontSize: '200px',
                marginBottom: '-120px',
  
                zIndex: '1',
                animation: [loaded ? 'slideInTop 0.8s' : 'none',
                            loaded2 ? 'slideOutRight 2.5s forwards' : 'none']
              }
            },
            ["La Baraque"]
          ),
  
          React.createElement(
            'div',
            {
              className: "animtext-studio",
              //key: 'text',
              style: {
                color: palette[4],
                fontSize: '340px',
                zIndex: '1',
                
                animation: [loaded ? 'slideInBottom 1.2s' : 'none',
                            loaded3 ? 'slideOutLeft 5s forwards' : 'none']
              }
            },
            ["Studio"]
          ),
  
  
          React.createElement(
            'div',
            {
              className: 'animtext-bruxelles-1',
              //key: 'text',
              style: {
                color: palette[0],
                marginTop: '-980px',
                fontSize: '700px',
                opacity: '0.9',
               
                animation: [loadedBx ? 'slideRightLeft 10s forwards' : 'none']
              }
            },
            ["Bruxelles"]
          ),
          React.createElement(
            'div',
            {
              className: 'animtext-bruxelles-2',
              //key: 'text',
              style: {
                color: palette[1],
                marginTop: '-80px',
                fontSize: '200px',
                opacity: '0.9',
               
                animation: [loadedBx ? 'slideRightLeft 10s forwards' : 'none']
              }
            },
            ["Bruxelles"]
          ),
  
  
  
          React.createElement(
            'div',
            {
              style: {
                opacity: 0,
                color: palette[0],
                position: 'absolute',
                height: '100%',
                width: '100%',
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center',
                fontSize: '600px',
                transform: 'translateX(200%)',
      //          overflow: 'hidden',
      //          backgroundColor: 'green',
               
                animation: [loadedSlide ?    'slideInRight 2s ease-out forwards' : 'none',
                            loadedNext ? 'showUp 0.8s forwards' : 'none',
                            ]
              }
            },
            [ 
              React.createElement(SectionsModule, { sectionClicked })
             ]
          ),
        ]
      )
    )
  }
  
  
