import React, { useEffect, useState } from 'react'


import { palette, sections } from '../constants';


import '../css/section-showreel.css'


export const SectionModule = ({ id, palette, name, left, top, sectionClicked }) => {
    const handleClick = () => { sectionClicked(id) };
  
    return (
      React.createElement(
        'button',
        {
          className: 'section-module',
          style: { left: left, top: top },
          onClick: handleClick, // Add onClick event handler
        },
        [
          React.createElement('div', { style: { cursor: 'pointer' }}, name)
        ]
      )
    );
  };